.ebutton {
    padding: 10px 35px;
    margin-left: 1rem;
    border-radius: 3px;
    font-weight: 600;
    border-color: #009688;
    font-size: 18px;
}
.ebutton:hover{
    padding: 10px 35px !important;
}

.tabular .p-datatable-wrapper {
    border-radius: 8px;
}

.aslabel {
    color: #009688;
    padding-bottom: 2px;
    font-family: 'Josefin Sans', sans-serif !important;
    margin-left: 0em;
    font-size: 16px;
    font-weight: 500;
}

.asinput-container {
    width: 100%;
}

.as-pformat {
    font-size: 11px;
    color: #909090;
    font-weight: 400
}

.as-card {
    padding: 0px;
    margin: 0px;
    width: 105em;
    margin-left: auto;
    margin-right: auto;
    height: 35;
    margin-top: 0em;
}


.image-items {
    display: flex;
    margin: 10px 0;
  }
  
  .iconcameras{
    margin-top: -2em;
    margin-left: 38%;
    position: absolute;
  }
  
  .iconpositions{
    position: absolute;
    width: 70px;
    height: 70px;
    background: #009688;
    border-radius: 50%;
  }

.addstafe-section{
    padding-bottom: 25px;
}
.manage-staff{
        color: white;
        text-transform: uppercase;
        font-size: 20px;
        text-align: center;  
        padding: 1.5rem;
        background-color: #009688;
        border-radius: 10px 10px 0px 0px;
}
.as-button{
    margin-top: 7rem !important;
}
.as-button, .as-button button{
    margin: 0px;
}
.inner-form{
    background-color: #fff;
    padding: 2rem;
}
@media(max-width:768px){
    .manage-staff{
        font-size: 18px;
    }
}