.new-readings {
  text-align: center;
  margin-bottom: 1rem;

}

.new-readings span:hover {
  color: #009688
}

.new-readings span {
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
  color: #414A5B;
  padding: 10px 0px 0px 10px;
  cursor: pointer;
}

.reading-right {
  text-align: left;
  display: flex;
}

.reading-right img {
  width: 25px;
}

.reading-section {
  border: 1px solid #EAEDF5;
  color: #544F56;
  border-radius: 10px;
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.video-section {
  display: flex;
}

.video-section p {
  text-align: left;
  margin: 15px;
}

.reading-section p {
  margin-bottom: 6px;
  font-size: 18px;
}

.video-timer {
  align-items: center;
  justify-content: space-evenly;
  display: flex;
}

.video-timer span {
  font-weight: 700;
  font-size: 40px;
  color: #009688;
}

.submit-btn1 {
  height: 50px !important;
}

.pdash-card {
  padding: 25px;
  margin-top: 1rem;
  box-shadow: 0px 4px 28px rgba(74, 114, 210, 0.15);
  border-radius: 8px;
  background: #ffffff;
  height: 100%;
  width: 100%;
}

.oliviaicondiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icondiv {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.pdroplist {
  display: flex;
  justify-content: center;
  background: linear-gradient(to bottom, #eaf6f4 35%, white 10%);
  padding: 1em;
  width: 100em;
  height: 10em;
  box-shadow: 0px 4px 28px rgba(74, 114, 210, 0.15);
}

.card1 {
  display: flex;
  height: 100%;
  align-items: center;
}

.card2 {
  display: flex;
  height: 100%;
  align-items: center;
  flex-wrap: wrap;
  padding: 2px;
}

.card3 {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.avatardiv {
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.textstyle {
  font-family: "Josefin Sans", sans-serif !important;
  color: #414a5b;
  font-weight: 600;
  font-size: 18px;
}

.textstyles {
  color: grey;
  font-size: 18px;
  width: 180px;
}

.spacediv {
  margin-bottom: 1rem;
}

.textstyles2 {
  color: grey;
  font-size: 18px;
}

.textstylecolor {
  color: #009688;
  font-weight: bold;
  font-size: 20px;
}

.bpcard {
  background: #ffffff;
  box-shadow: 0px 4px 28px rgba(74, 114, 210, 0.15);
  border-radius: 5px;
  height: 100%;
}

.maintext {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  margin-left: 0em;
}

.maintext1 {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  margin-top: 2%;
  margin-left: 0em;
}

.editicon {
  margin-left: 100%;
}

.delicon {
  margin-left: 100%;
}

.avatardiv .p-avatar.p-avatar-image {
  width: 62%;
  height: 93%;
}

.avatarimg {
  width: 145px;
  height: 145px;
  display: flex;
  overflow: hidden;
  position: relative;
  font-size: 1.25rem;
  align-items: center;
  flex-shrink: 0;
  line-height: 1;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
}

.cardstyle {
  display: flex;
  margin-top: 5%;
  justify-content: space-between;
  align-items: center;
}

.p-datatable-responsive-scroll>.p-datatable-wrapper {
  border-radius: 5px;
}

.bpcardtext {
  font-family: "Josefin Sans", sans-serif !important;
  color: #848b97;
  font-weight: 600;
  margin-top: 12%;
  margin-left: 1em;
  font-size: 16px;
  border-radius: 7px;
}

.bpcardtext1 {
  font-family: "Josefin Sans", sans-serif !important;
  color: #848b97;
  font-weight: 600;
  margin-top: 10%;
  margin-left: 1em;
  font-size: 16px;
  border-radius: 7px;
}

.bpcardtextstyle {
  font-family: "Josefin Sans", sans-serif !important;
  color: #414a5b;
  font-weight: 700;
  margin-top: 15%;
  font-size: 40px;
  margin-left: 0.5em;
}

.bpcardtextstyle1 {
  font-family: "Josefin Sans", sans-serif !important;
  color: #414a5b;
  font-weight: 700;
  margin-top: 15%;
  font-size: 40px;
  margin-left: 0.5em;
}

.bpcardtextstyles {
  font-family: "Josefin Sans", sans-serif !important;
  color: #003366;
  font-weight: 400;
  margin-top: 6%;
  font-size: 16px;
  margin-left: 1em;
}

.bpcardtextstyles1 {
  font-family: "Josefin Sans", sans-serif !important;
  font-weight: 400;
  margin-top: 8%;
  font-size: 16px;
  color: #00acee;
  margin-left: 1em;
}

.bpcardtextstyles2 {
  font-family: "Josefin Sans", sans-serif !important;
  color: #ff9800;
  font-weight: 400;
  margin-top: 17%;
  font-size: 16px;
  margin-left: 1em;
}

.bpcardtextstyles3 {
  font-family: "Josefin Sans", sans-serif !important;
  color: #8bba47;
  font-weight: 400;
  margin-top: 27%;
  font-size: 16px;
  margin-left: 1em;
}

.bpcardtextstyles4 {
  font-family: "Josefin Sans", sans-serif !important;
  color: #e91e63;
  font-weight: 400;
  margin-top: 5%;
  font-size: 16px;
  margin-left: 1em;
}

.femaleicon {
  font-size: 18px;
}


.graphcard {
  margin-top: 1%;
  overflow-x: scroll !important;
  cursor: pointer;
  direction: rtl;
}

/* .p-card .p-card-body{
  width: 200em;
} */

.inputtextcard {
  width: 100%;
  border: 0.5px solid #adb7d8;
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 0px 4px 28px rgba(74, 114, 210, 0.15);
  border-radius: 8px;
  padding: 15px;
}

.arrow1 {
  cursor: pointer;
}

.datelist {
  border-radius: 5px;
  justify-content: center;
  display: flex;
  padding: 0.1em;
  margin-top: 1em;
  /* background-color: #e91e63; */
}

.inputflex {
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
  margin: 0%;
}

.doctortext {
  width: 100%;
  font-family: "Josefin Sans", sans-serif !important;
  font-size: small;
}

.inputrow {
  display: flex;
  flex-direction: row;
  margin-top: 1%;
}

.checktickimg {
  margin-top: 5%;
}

.datebutton {
  width: "25%"
}

.flexsplit {
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
}

.bottomcardstyle {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 2%;
}

.bottomcard {
  width: 556px;
  height: 330px;
  background: #ffffff;
  box-shadow: 0px 4px 28px rgb(74 114 210 / 15%);
  border-radius: 8px;
  margin-bottom: 4em;
}

.bottomcardtext {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  padding: 20px;
}

.lastcardtext {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  color: #544f56;
}

.linegraphcard {
  background: #ffffff;
  box-shadow: 0px 4px 28px rgba(74, 114, 210, 0.15);
  border-radius: 8px;
  margin-top: 1%;
  margin-bottom: 1%;
  padding: 1rem;
  overflow-x: scroll;
  direction: rtl;
}

.pdashboard-dropdown {
  width: 99%;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  margin-bottom: 1%;
  font-family: "Josefin Sans", sans-serif !important;
  color: #009688;
  font-size: 15px;
}

.averagetext {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  font-family: "Josefin Sans", sans-serif !important;
  color: #8b8b8b;
  padding: 1rem;
  justify-content: start;
  display: flex;
  margin-top: 2em;
}

.averagetable {
  box-shadow: 0px 4px 28px rgb(74 114 210 / 15%);
  border-radius: 8px;
  width: 100%;
  height: 20vh;
}

.averagetabletext {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 13.5px;
  line-height: 100%;
  text-transform: uppercase;
  color: #373737;
  white-space: nowrap;
  padding: 0rem 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

.averagetexttablenum {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 13.5px;
  line-height: 100%;
  text-transform: uppercase;
  color: #373737;
  text-align: left;
  margin-top: 3%;
}

.tablecard {
  background: #ffffff;
  box-shadow: 0px 4px 28px rgba(74, 114, 210, 0.15);
  border-radius: 8px;
  margin-top: 1%;
}

.seperatedropdown .p-dropdown .p-dropdown-label.p-placeholder {
  font-family: "Josefin Sans", sans-serif !important;
  color: #009688;
  font-size: 15px;
  font-weight: 700;
}

.seperated {
  margin-top: 3%;
}

.patienttablecard {
  margin-top: 1%;
  width: 99%;
  height: 30vh;
  background: #ffffff;
  box-shadow: 0px 4px 28px rgba(74, 114, 210, 0.15);
  border-radius: 8px;
}

.srymsg {
  display: flex;
  justify-content: center;
  margin-top: 10e;
  padding-top: 12em;
}

.pinformation {
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  color: #009688;
  font-weight: 800;
  font-size: 15px;
  font-family: "Josefin Sans", sans-serif !important;
  margin-bottom: 1em;
}

.patientcard {
  margin-top: 1%;
}

.p-buttonset .p-button:first-of-type {
  padding: 1.1em;
}

.p-buttonset .p-button:last-of-type {
  padding: 1.1em;
}

.p-buttonset .p-button:not(:first-of-type):not(:last-of-type) {
  padding: 1.1em;
}

.p-buttonset .p-button:not(:first-of-type):not(:last-of-type) {
  padding: 1.1em;
}

.patientcards {
  width: 100%;
  height: 25vh;
  background: #ffffff;
  box-shadow: 0px 4px 28px rgba(74, 114, 210, 0.15);
  border-radius: 8px;
}

.edidicon {
  margin-right: 1em;
  cursor: pointer;
}

.preadings {
  padding: 30px;
  border-radius: 10px 10px 0px 0px;
}

.readingstyle {
  font-weight: 700;
  font-size: 20px;
  color: #8b8b8b;
  font-family: "Josefin Sans", sans-serif !important;
  margin-bottom: 1em;
}

.tabular .p-datatable-wrapper {
  border-radius: 8px;
  overflow-x: auto;
}

.updatecardtext {
  font-family: "Josefin Sans", sans-serif !important;
  font-style: normal;
  font-size: 18px;
  line-height: 100%;
  margin-top: 2%;
  margin-bottom: 2%;
  font-weight: bold;
}

.rpmbutton {
  margin-left: 40%;
}

.dtcorner {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 3em;
  font-size: 13px;
  font-weight: 400;
  color: #8b8b8b;
}

.pressureadiv {
  width: 70%;
}

.p-button:not(button):not(a):not(.p-disabled):active {
  background: #009688;
  color: #ffffff;
  border-color: #009688;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: black;
}

.p-inputtext:enabled:focus {
  box-shadow: none !important;
}

.p-inputtext:enabled:hover {
  border-color: gray;
}

.popupclose-btn {
  border-bottom: transparent !important;
}

.blooddiv {
  display: flex;
  flex-direction: row;
  margin-top: 1%;
  justify-content: space-evenly;
}

.icondiv1 {
  display: flex;
}

.select-datefield {
  display: flex;
  justify-content: end;
}

.date-overlay input {
  padding: 12px 10px;
  cursor: pointer;
}

.doctick {
  cursor: pointer;
  margin-left: 1em;
}

.monthly-callendar {
  display: flex;
  align-items: center;
  justify-content: end;
}

.date-overlay input:focus {
  box-shadow: none !important;
}

.healthcare {
  margin-top: 1.5rem;
}

.pulse-text {
  align-items: center;
  justify-content: start;
  display: flex;
}

.submit-btn {
  display: flex;
  justify-content: center;
  align-items: end;
}

.docmenu {
  margin-top: 0.5em;
  margin-left: 1em;
}

.p-accordion-header-text {
  color: #009688;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  box-shadow: 0 0 0 0.2rem lightgray;
}

.arrowmoveon {
  display: flex;
  margin-top: 1rem;
}

.dateandtime {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-y: hidden;
  overflow-x: auto;
  width: 100%;
  padding: 0rem 1rem;
}

.userview-portal {
  height: 150px !important;
  width: 150px !important;
  margin: auto;
}

.space-icon,
.option-select {
  margin-right: 10px;
  cursor: pointer;
}

.space-icon:last-child {
  margin-right: 0px;
}

.active-img {
  align-items: center;
  justify-content: center;
  display: flex;
}

.monthly-timer span {
  font-size: 15px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Josefin Sans' !important;
}

.monthly-timer {
  border: 1px solid #EAEDF5;
  color: #544F56;
  border-radius: 10px;
  padding: 15px !important;
  text-align: center;
  background-color: #E27785;
}

.detail-timersec {
  display: flex;
  align-items: center;
}



@media(max-width:1440px) {
  .docmenu {
    margin: 1rem 0.5rem;
  }
}

.detail-leftside {
  display: flex;
}

.avadar-image {
  height: 160px;
  width: 160px;
  object-fit: cover;
}

.patient-details {
  background-color: #fff;
  padding: 20px;
}

.overall-patient {
  margin-top: 2rem;
  margin-left: 1rem;
}

.profile-right {
  padding: 0px;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column !important;
}

.biodata-text {
  padding-left: 0px !important;
}

.timeprogress {
  margin-top: 1rem !important;
}

.selected-option {
  margin-left: 1rem;
}

.grapy-section {
  display: flex;
}

.barchart canvas {
  width: 100% !important;
}

.record-tables {
  margin: 1rem 0rem;
}

.patient-contact {
  margin: 0px !important;
  padding: 0px !important;
  list-style: none;
}

.timer-logo1 {
  cursor: pointer;
}

.patient-contact li {
  padding: 7px 0px;
}


/* button:focus:not(:focus-visible){
  border:3px solid black !important;
} */

@media(device-width:1600px) {
  .date-overlay input {
    width: 100px !important;
  }
}

@media(max-width:1600px) {

  .video-overlay {
    padding: 7px !important;
    margin: 0px !important;
  }
}

@media(max-width:1440px) {
  .textstyles {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 115px;
  }

  .date-overlay input {
    width: 100px !important;
  }
}

@media(device-width:1280px) {

  .spacediv,
  .textstyles2 {
    font-size: 16px;
  }

  .monthly-timer span {
    font-size: 12px;
  }

  .userview-portal {
    height: 130px !important;
    width: 130px !important;
    margin: auto;
  }

  .timer-logo1 img {
    width: 25px;
  }

  .monthly-timer {
    padding: 12px !important;
  }
}

@media(max-width:1024px) {

  .monthly-timer span {
    font-size: 12px;
  }
}

@media(max-width:768px) {

  .new-readings {
    margin-top: 1rem;
  }

  .video-overlay {
    margin: auto;
    margin-top: 1rem;
  }

  .patient-contact {
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width:599px) {

  .textstyle,
  .maintext,
  .femaleicon,
  .textstyles2 {
    text-align: center;
  }

  .detail-timersec {
    justify-content: center;
  }

  .profile-right,
  .detail-leftside {
    padding-left: 0px !important;
  }
}

@media(max-width:575px) {
  .grapy-section {
    display: block;
    text-align: center;
  }

  .selected-option {
    margin: 1rem 0rem 0rem 0rem;
  }

  .averagetext {
    font-size: 14px;
  }

  .p-datatable .p-datatable-tbody>tr>td,
  .p-datatable .p-datatable-thead>tr>th {
    padding: 0.8rem 1rem !important;
    font-size: 13px !important;
  }
}

@media(max-width:425px) {
  .monthly-timer {
    margin-top: 10px;
  }
}

@media(max-width:375px) {
  .bpcardtextstyle {
    margin-top: 0px;
  }

  .mobile-menu svg [fill] {
    left: -3rem;
    position: relative;
  }
}

.confirntext {
  justify-content: left !important;
}

.MuiTooltip-tooltip p {
  font-size: 14px !important;
  margin-bottom: 0px;
}

.enddate,
.start-date {
  margin-right: 1rem !important;
}

.option-select .p-selectbutton {
  display: flex;
}

/* .averagetable-scroll {
  overflow-x: auto;
} */

.averagetable-scroll::-webkit-scrollbar {
  height: 4px;
}

.iconstyle {
  padding-right: 2rem;
}

.ccm-section .new-readings {
  width: 100%;
  text-align: left;
}

.ccm-section .video-overlay {
  margin-left: 0rem;
}

.footer-delete button.p-button.p-component {
  padding: 6px 12px;
}

@media (min-width:1200px) and (max-width:1360px) {

  .pulse-text,
  .column-text {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}

@media (min-width:900px) and (max-width:1360px) {

  .detail-leftside,
  .profile-right {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}

@media (max-width:899px) {
  .patient-contact li {
    text-align: center;
  }
}

@media (max-width:450px) {

  .enddate,
  .start-date,
  .submit-btn {
    max-width: 100% !important;
    flex-basis: 100% !important;
    margin-right: 0rem !important;
    margin-bottom: 1rem !important;
  }

  .select-datefield {
    display: block;
  }

  .oliviaicondiv {
    display: block;
  }

  .space-icon img,
  .space-icon {
    width: 40px;
  }

  .option-select span {
    font-size: 12px;
  }

  .icondiv {
    justify-content: right;
  }

  .option-select .p-button.p-component {
    padding: 0.2rem;
    height: 40px;
  }
}

.carousel-content>* {
  margin: 0.5rem;
}