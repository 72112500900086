.select {
  font-family: 'Josefin Sans', sans-serif !important;
  font-weight: 500;
  margin-left: 4%;
}

.copyright {
  margin-top: 0.5rem;
}

.foot1 {
  color: #959191;
  text-align: center;
  margin-top: 15px;
}

.foot1 svg {
  top: 3px;
  position: relative;
}

.rinput-container {
  margin: 1em;
  width: 100%;
  margin-top: 5px;
  padding-top: 5px;
  margin-bottom: 10px;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: #009688;
}

.text-Container {
  display: flex;
  justify-content: center;
  color: #009688;
  padding-top: 1rem;
  font-family: 'Josefin Sans', sans-serif !important;
}

.select {
  font-family: 'Josefin Sans', sans-serif !important;
  font-weight: 500;
  text-align: center;
}

.foot1 {
  color: #959191;
  margin-top: 8%;
  margin-bottom: 0%;
  margin-left: 25%;
}

.reg-inputfield {
  padding-bottom: 5px;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: #009688;
}

.navigation-tab.p-tabview-ink-bar {
  left: 10px !important;
}

.rbtn1 {
  width: 47%;
  margin-right: 0%;
  border-radius: 0%;
  font-family: 'Josefin Sans', sans-serif !important;
  border-color: #009688;
  display: flex;
  justify-content: center;
  font-size: 1.1em;
}

.rbtn1:active {
  border: 1px solid black;
}

.rbtn2 {
  border-radius: 0px;
  font-family: 'Josefin Sans', sans-serif !important;
  border-color: #009688;
  display: flex;
  justify-content: center;
}

.regis-Container {
  display: flex;

  justify-content: space-around;
}

.button-regis {
  display: flex;
  justify-content: center;
}

.style {
  font-family: 'Josefin Sans', sans-serif !important;
  margin-top: 0.3em;
}

.loginText {
  color: #009688;
  font-weight: 500;
  margin-top: 0em;
}

.loginText:hover {
  cursor: pointer;
  color: #009688;
}

@media(max-width:425px) {
  .text-Container {
    display: block;
    text-align: center;
  }

  .signup-text {
    margin-bottom: 10px;
  }
}

@media(max-width:320px) {
  .select {
    margin-left: 0px !important;
  }
}