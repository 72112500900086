.notifycardpop {
  border-radius: 8px;
  flex-wrap: wrap;
  background-color:black;
  padding: 1rem;
  }
  
  .entiredivpop {
    display: flex;
    justify-content: center;
    /* margin-top: 20%; */
    /* padding: 10px; */
    top: 50%;
    transform: translate(0%, 100%);
    position: absolute;
    top: 20%;
    left: 50%;
    border: 1px solid black;
    z-index: 10;
  }
  
  .notifyphonedivpop {
    text-align: center;
  }
  
  .notifycalldiv {
    justify-content: space-evenly;
    display: flex;
    /* padding: 3px; */
    margin-top: 5%;
    align-content: center;
  }
  .notifyphoneflexpop {
    flex-direction: column;
    margin-top: 5%;
  }
  .notifyphonetextpop {
    font-family: 'Josefin Sans', sans-serif !important;
    font-style: normal;
    font-size: 30px;
    color: rgb(15, 15, 15);
  }
  .notifyphonenumbpop {
    font-family: 'Josefin Sans', sans-serif !important;
    font-style: normal;
    font-size: 15px;
    color: rgb(7, 7, 7);
    margin-top: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .endcalldiv {
    width: 50px;
    height: 50px;
  }
  .notifyflex{
    text-align: right;
  }
  .addpersonvedio{
    width: 23em;
    position: relative;
    top: -30em;
    padding-top: -1em;
    margin-bottom: 2em;
    margin-left: 82em;
    background: white;
    height: 49em;
    border-radius: 5px;
  }

  .addpar{
    display: flex;
    flex-direction: row-reverse;
    position: relative;
    margin-right: 4em;
    bottom: 30em;
  }

  .othericon{
    display: flex;
    justify-content: center;
    padding-top: -62em;
    position: relative;
    bottom: 30em;
  }