.userlabel {
  margin-right: 70%;
  color: #009688;
  padding-bottom: 2px;
  font-family: 'Josefin Sans', sans-serif !important;
}

.btn-container {
  width: 100%;
  justify-content: center;
  border-color: #009688;
  border-radius: 3px;
  display: flex;
  margin-top: 1rem;
}

.header-field {
  background-color: white;
  padding: 1.5em;
  margin-bottom: 0px;
}

.para {
  text-align: center;
  color: #adaaa1;
  margin-top: 2.5em;
  margin-bottom: 1.99em;
  margin: 1rem 0rem;
  font-family: 'Josefin Sans', sans-serif !important;
}

.para:hover {
  cursor: pointer;
  color: #009688;
}

.input-container {
  margin: 1em;
  width: 100%;
  margin-top: 5px;
  padding-top: 5px;
  margin-bottom: 10px;
  font-family: 'Josefin Sans', sans-serif !important;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: #009688;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: 0;
}

.foot {
  color: rgb(132, 126, 126);
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}

.foot svg {
  margin: 4px;
}



.header-container {
  margin-left: auto;
  margin-right: auto;
  width: 360px;
}

.header-content {
  color: white;
  font-size: 4em;
  display: flex;
  justify-content: center;
  padding-bottom: 10px;

}

.header-rpm {
  color: white;
  margin-top: -1.2em;
  font-size: 2em;
  display: flex;
  justify-content: center;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: #009688;
}

.label {
  font-size: 16px;
  color: #009688;
  margin-bottom: 5px;
}

.password-field {
  margin-bottom: 0px;
  margin-top: 1rem;
}

.Toastify__toast-container--top-right {
  z-index: 9999999;
}

@media(max-width:425px) {
  .header-content {
    font-size: 3em;
  }

  .header-rpm {
    font-size: 1.5em;
  }

  .header-container {
    width: 300px;
  }
}

@media(max-width:320px) {
  .header-container {
    width: 100%;
    padding: 0.5rem;
  }

  .css-k4qjio-MuiFormHelperText-root.Mui-error {
    font-size: 11px;
  }
}

.informationsec button.p-button {
  margin-top: 1rem;
}

.ftext div,
.ftext div a {
  font-family: 'Red Hat Display', sans-serif !important;
  color: #726C6C;
  font-weight: 500;
}