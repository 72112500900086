.create-card {
  display: flex;
  width: 108em;
  height: 45em;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0px;
}

.create-head {
  color: white;
  font-size: 20px;
  display: flex;
  margin-left: 1em;
  margin-top: -0.5em;
  padding-top: 2%;
}

.ocards {
  background-color: #f0f1f7;
  padding-bottom: 2em;
}

.hello {
  display: flex;
  justify-content: center;
  font-family: 'Josefin Sans', sans-serif !important;
  font-weight: 500;
  margin-top: 1%;
  font-size: 20px;
  color: #686868;

}

.hello1 {
  display: flex;
  justify-content: center;
  font-family: 'Josefin Sans', sans-serif !important;
  font-weight: 500;
  font-size: 20px;
  color: #686868;

}

.cinput-container {
  width: 100%;
}

.organization-label {
  color: #009688;
  font-size: 16px;
  margin-bottom: 0.5em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

.cfield-container {
  margin: 1em;

}

.org-head {
  color: white;
  text-transform: uppercase;
  font-size: 20px;
  padding-top: 1%;
  font-weight: 500;
  padding-bottom: 1%;
  margin-left: 1em;
}

.bspace {
  margin: 15px 10px 0px;
}

.create-button {
  color: white;
  background-color: #009688;
  border-radius: 3px;
  border-color: #009688;
}

.holder .p-dropdown .p-dropdown-label.p-placeholder {
  color: #6c757d;
  font-weight: 400;
  font-size: 15px;
  padding-top: 13px;
}

.wrap {
  display: flex;
  flex-wrap: wrap;
}

.p-dropdown .p-dropdown-trigger {
  color: #6c757d;
}

.overall-ocard {
  background: rgb(255, 255, 255);
  border-radius: 0px 0px 10px 10px;
  width: 100%;
}

.organization-part {
  padding: 0px !important;
}

.org-margin {
  margin: 0px !important;
  width: 100% !important;
}

.orgdropdown {
  background-color: white;
  width: 23em;
}

.orgwrap {
  padding: 2rem;
}

.css-k4qjio-MuiFormHelperText-root.Mui-error {
  font-size: 12px;
  margin: 5px 0px 0px 0px;
}

.phoneformat {
  color: #909090;
  font-weight: 400;
  font-size: 11px;
}

.inputmask {
  height: 2.5em;
  width: 24em;
}
.org-rowgap {
  width: 100% !important;
  margin: 0px !important;
}
.org-rowgap .MuiGrid-root {
  padding: 10px !important;
}
.select-Membership {
  width: 100%;
  height: 40px;
}
.select-Membership span.p-dropdown-label.p-inputtext {
  padding: 9px;
  color: #000;
}
@media(max-width:1199px) {
  .org-head {
    font-size: 18px;
  }

  .hello1 {
    text-align: center;
  }

  .orgwrap {
    padding: 1rem;
  }
}
@media(max-width:991px) {
  .hello {
    text-align: center;
    font-size: 17px;
  }

  .hello1 {
    font-size: 17px;
  }
  .overall-ocard{
    height: 100vh;
    overflow: hidden scroll;
  }
}

@media(max-width:767px) {
  .bspace {
    text-align: center;
  }
}

@media(max-width:425px) {

  .hello,
  .hello1 {
    font-size: 15px !important;
  }

  .olabel {
    font-size: 15px;
  }

  .orgwrap input::placeholder {
    font-size: 15px !important;
  }
}