

.reset-head {
  color: white;
  font-size: 4em;
  display: flex;
  justify-content: center;
  margin-bottom: -0.3em;
}

.change-inputfield{
  padding-bottom: 5px;
}
.line {
  color: rgb(125, 127, 146);
  margin-top: 19%;
  display: flex;
  font-weight: bold;
}

.size {
  width: 21rem;
  border-radius: 4px;
  height: 2.5rem;
}

.button-forgots {
  font-family: 'Josefin Sans', sans-serif !important;
  justify-content: center;
  border-color: #009688;
  border-radius: 3px;
  font-weight: 500;
  font-size: 18px;
  padding: 7px 25px;
}
.button-forgots:hover{
  padding: 7px 25px !important;
}

.input-container {
  margin: 1em;
  width: 100%;
  margin-top: 5px;
  padding-top: 5px;
  margin-bottom: 10px;
}