.careprovider {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 4px 28px rgba(74, 114, 210, 0.15);
    display: flex;
    width: 450px;
}
.careprovider img {
    width: 70px;
    height: 70px;
}
.careprovider p {
    font-family: 'Josefin Sans' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #414A5B;
    margin-bottom: 0px;
    text-align: center;
}
/* .careprovider .p-checkbox-box {
    width: 50px;
    height: 50px;
    border-radius: 20px;
} */
.videochat-text p{
font-family: 'Josefin Sans' !important;
font-weight: 700;
font-size: 18px;
text-transform: uppercase;
color: #414A5B;
margin-bottom: 0px;
}
.videochat-text {
    margin: 2.5rem 1rem 1rem 1rem;
}
.Appointment-section{
    background-color: #fff;
    box-shadow: 0px 4px 28px rgba(74, 114, 210, 0.15);
    border-radius: 20px;
}
.Appointment-section p{
font-family: 'Josefin Sans' !important;
font-style: normal;
font-weight: 500;
font-size: 20px;
color: #414A5B;
margin-bottom: 0px;
padding: 1rem;
}

.next-appoint {
    text-transform: uppercase;
    color: #FFFFFF;
    background: #009688;
    border-radius: 4px;
    margin: 20px;
    padding: 1rem;
    font-weight: 700;
}
.condition{
    text-align: center;
    padding: 2rem;
}
.condition p{
    font-family: 'Josefin Sans' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 100%;
    text-transform: uppercase;
    color: #009688;
}
.selectday p{
    font-family: 'Josefin Sans' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    color: #414A5B;
    padding: 20px;
    margin-bottom: 0px;
}
.convenient{
    text-align: right;
}
.convenient button{
    background: #0081FF;
    border-radius: 5px;
    color: #FFFFFF;
    padding: 10px 20px;
}
.careprovider-sec{
    padding: 0px !important;
    justify-content: center;
    align-items: center;
    display: flex;
}
.selectbox{
    padding-left: 2rem;
    cursor: pointer;
}
.status-table{
    background-color: #FFFFFF;
    border-radius: 10px;
}
.table-head th{
text-align: center;
font-size: 18px;
font-family: 'Josefin Sans' !important;
font-style: normal;
font-weight: 600;
text-transform: uppercase;
color: #373737;
}

.table-datas td{
    text-align: center;
    font-size: 18px;
}
.status-header {
    background-color: #99D5CF;
}
.table-datas .Mui-checked {
    color: #33B466 !important;
    cursor: pointer;
}

@media(max-width:1024px){
   
}



@media(max-width:768px){
    .videochat-text p {
        font-size: 14px;
    }
    .selectday p{
        font-size: 18px;
    }
    .convenient button{
        margin-bottom: 10px;
    }

}
@media(max-width:425px){
    .careprovider{
        width: 100%;
    }
}