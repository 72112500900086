.imagecard {
  background-color: #fff;
  height: 100%;
}

.inputcard {
  background-color: #fff;
  height: 100%;
}

.textprofile {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  text-transform: uppercase;
  color: #414A5B;
}

.textlable {
  color: #414A5B;
  font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.label-format {
  margin: 0px;
}container

.profile-save {
  margin-top: 3rem;
}

.propic-card {
  background: #fff;
  padding: 15px;
  border-radius: 0px 0px 10px 10px;
  height: 100%;
}

.proinput-container {
  width: 36em;
}

.input-container1 {
  margin: 1em;
  width: 85%;
  margin-top: 5px;
  padding-top: 5px;
  margin-bottom: 10px;
}

.imageupload {
  border: none;
}

.pro-btn {
  width: 9em;
  justify-content: center;
  border-radius: 3px;
}

.pro-btn:hover {
  padding: 0.75rem 1.25rem !important;
}

.phoneformat1 {
  color: #909090;
  font-weight: 400;
  font-size: 15px;
}

.ptopic {
  color: #373737;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  font-family: 'Josefin Sans', sans-serif !important;
}

.tabular .p-datatable .p-datatable-tbody>tr>td {
  font-size: 18px;
}

.image-item {
  margin: 10px 0;
}

.iconcamera {
  position: absolute;
  height: 2.5em;
  bottom: -1rem;
  width: 100%;
}

.iconposition {
  position: absolute;
  width: 70px;
  height: 70px;
  background: #009688;
  border-radius: 50%;
}

.user-profile {
  text-align: center;
}
.profile-pic{
  border-radius: 10px 10px 0px 0px;
  height: 100%;
}

.username {
  padding-top: 1rem;
}

.profile-text {
  margin-top: 20px;
  padding-left: 10px;
}

.inputWithButton {
  position: relative;
    height: 39px;
    width: 83px;
    color: white;
    background: #009688;
    left: 0.5em;
    bottom: 0em;
    border-radius: 3px;
}

.inputWithButton input{
    width: 70%;
    height: 25px;
    padding-right: 60px;
}

.inputWithButton button {
  position: absolute;
  right: 0;
  top : 5px;
}

@media(max-width:1440px) {
  .input-container1 {
    width:79%;
  }
}

@media(max-width:1280px) {
  .input-container1 {
    width:76%;
  }
}

@media(max-width:1199px) {
  .ptopic {
    font-size: 15px;
  }
}

@media(max-width:1024px) {
  .input-container1 {
    width: 69%;
  }
}

@media(max-width:991px) {
  .information-field {
    display: block;
  }
}

@media(max-width:899px) {
  .profile-pic {
    padding-bottom: 4rem !important;
  }
  .input-container1{
    width:88%;
  }
}