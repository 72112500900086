/* .p-datatable-responsive-scroll > .p-datatable-wrapper {
  border-radius: 10px;
} */


.ml-title1 {
  font-size: 18px;
  font-weight: 800;
  font-family: 'Josefin Sans', sans-serif !important;
  color: #414A5B;
  padding: 20px;
}

.card9 {
  margin-top: 1em;
}

.ml-text1 {
  font-family: 'Josefin Sans', sans-serif !important;
}

/* .p-datatable-responsive-scroll > .p-datatable-wrapper {
  border-radius: 10px;
} */

.p-datatable table {
  border-collapse: collapse;
  min-width: 100% !important;
  table-layout: fixed;
}