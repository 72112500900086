.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-stretch {
  align-items: stretch;
}

.flex-vert-center {
  align-items: center;
}
.flex-center {
  align-items: center;
  justify-content: center;
}

.flex-align-justify-center {
  align-content: center;
  justify-content: center;
}

.space-evenly {
  justify-content: space-evenly;
}

.space-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-end {
  justify-content: flex-end;
}

.flex-one {
  flex: 1;
}

.space-around {
  justify-content: space-around;
}

.pi {
  font-family: "primeicons" !important;
}

@for $i from var(--from) to var(--to) {
  .flex-$i {
    flex: 0$ (i);
  }
}
