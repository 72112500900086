.p-datatable.p-datatable-selectable .p-datatable-tbody>tr.p-selectable-row:focus {
    outline: none;
}

.p-datatable .p-datatable-thead>tr>th {
    background-color: #99D5CF;
    white-space: nowrap;
}

.dcard {
    background-color: white;
    border-radius: 10px;
    cursor: pointer;
    padding: 1rem;
    height: 100%;
}

.main-Container1 {
    display: flex;
    flex-wrap: wrap;
    margin: 1em;
    margin-left: 1em;
    margin-top: -0.5em;
}

.p-datatable .p-sortable-column:focus {
    box-shadow: none;
}

.image-container {
    display: flex;
    justify-content: space-between;
    margin-left: 8%;
}

.dashtable {
    width: 100%;
    margin-top: 2em;
    font-family: 'Josefin Sans', sans-serif !important;
    color: #A1A1A1;
}

.card-hghlght {
    pointer-events: auto;
    transform: scale(1);
    transition: all 0.4s;
}

.card-hghlght:hover {
    opacity: 5;
    transform: scale(1.02);
    box-shadow: 0 5px 20px 0 #143D55;
}

.cardsection {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dash-card-section {
    font-size: 22px;
    font-weight: 600;
    color: #009688;
    display: flex;
    align-items: center;
}

.dash-card-img {
    margin-right: 2rem;
}

.dashbadd-patient {
    color: #009688;
    cursor: pointer;
}

.seach-btn {
    z-index: 9;
    margin-right: 1rem;
}

.seach-btn i.pi.pi-search {
    margin-top: 0rem;
    position: absolute;
    top: 1rem;
    left: 0.8rem;
}

.dashbsearch-patient {
    display: flex;
    justify-content: end;
}

/* .overflow-ellipsis {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    display: inline-block !important;
    white-space: nowrap !important;
    position: relative !important;
    width: 150px !important;
} */

.number {
    font-family: 'Josefin Sans', sans-serif !important;
    color: #585858;
    font-size: 3.6em;
    font-weight: bold;
}

.patient {
    color: #414A5B;
    font-size: 20px;
    font-weight: bold;
    height: 100%;
    display: flex;
    align-items: center;
}

.img-icon {
    margin-right: 7em;
    margin-top: 1em;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
}

.tabular .p-datatable-wrapper {
    border-radius: 8px;
}

.icon-container {
    text-align: right;
}

.vedioicon:hover {
    cursor: pointer;
}

.lineone {
    color: #97999B;
    font-family: 'Josefin Sans', sans-serif !important;
    font-weight: 500;
    font-size: 14px;
    text-align: end;
}

.tabular .p-datatable-wrapper {
    border-radius: 25px;
    overflow-x: auto;
}

.patname {
    padding-left: 10px;
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
    color: #373737;
    background: #99D5CF;
}

.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
    background: #99D5CF;
    color: #373737;
}

.p-datatable .p-sortable-column.p-highlight {
    background: #99D5CF;
    color: #373737;
}

.dashb-search i.pi.pi-search {
    top: 25px;
}

@media(max-width:1199px) {
    .patient-text {
        text-align: center;
    }

    .icon-container {
        text-align: center !important;
    }

    .tabular .p-datatable .p-datatable-tbody>tr>td {
        font-size: 15px;
    }

    .patient {
        text-align: left;
    }

    .img-icon {
        text-align: left;
    }

    .datatable-section {
        margin-right: -14rem;
        width: 100%;
        overflow-x: auto;
    }

    .number {
        font-size: 2.5rem;
    }
}

@media(max-width:991px) {
    .tabular {
        margin: auto;
    }
}

@media(max-width:425px) {
    .number {
        font-size: 2rem;
    }
}