.footer{
  padding: 0.5rem;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  left: 10em;
  height: 50px;
  width: calc(100% - 160px);
  z-index: 9;
  }

  .ftext{
    display: flex;
    justify-content: center;
    color: rgb(132, 126, 126);
    height: 35px;
    align-items: center;
    margin-top: 1rem;
  }

  @media(max-width:1199px){
    .footer{
      left: 10em;
      z-index: 9;
    }
  }
  @media(max-width:980px){
    .footer{
      left: 0rem;
      width: 100%;
    }
  }
  @media(max-width:768px){
    .footer{
      left: 0rem;
      width: 100%;
    }
  }