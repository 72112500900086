.escard {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  background-color: #eff2fc;
  margin-top: -2em;
  padding-bottom: 2em;
}

.estaff-ocard {
  background: rgb(255, 255, 255);
  padding: 20px;
  border-radius: 0px 0px 10px 10px;
}

.sedittext {
  color: #eff2fc;
  padding: 1em;
  font-size: 18px;
  font-weight: 900;
}

.esinput-container {
  width: 100%;
}

.edits-btn {
  margin-top: 7rem;
}

.edits-button {
  padding: 10px 35px;
    border-radius: 3px;
    font-weight: 600;
    border-color: #009688;
    font-size: 18px;
}
.edits-button:hover {
  padding: 10px 35px !important;
}
.eslabel {
  color: #009688;
  padding-bottom: 2px;
  font-size: 16px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.esinputfield {
  width: 97%;
  margin-left: 1.5em;
  margin-bottom: 1em;
  margin-top: 0.5em;
}

.esinputfield1 {
  width: 100%;
  margin-left: 0.5em;
  margin-bottom: 1em;
  margin-top: 0.5em;
}

.msbutton {
  width: 12%;
  color: white;
  background-color: #009688;
  border-radius: 3px;
  font-weight: 500;
  font-family: 'Josefin Sans', sans-serif !important;
  justify-content: center;
  border-color: #009688;
  margin-left: 2em;
  height: 3em;
}

.es-format {
  font-size: 15px;
  color: #909090;
  font-weight: 400
}

.eslabel-space {
  margin-bottom: 0.5em;
}

.staff-edit{
  color: white;
  text-transform: uppercase;
  font-size: 20px;
  background: #009688;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
}
.editbox{
  padding-bottom: 25px;
}