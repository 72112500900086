.md-title {
    font-size: 18px;
    font-weight: 800;
    font-family: 'Josefin Sans', sans-serif !important;
    color: #414A5B;
    padding: 20px;
}

.md-title1 {
    font-size: 18px;
    font-weight: 800;
    font-family: 'Josefin Sans', sans-serif !important;
    color: #414A5B;
    padding: 20px;
    margin-top: 2%;
}

.md-dcard {
    width: 100%;
    height: auto;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 28px rgba(74, 114, 210, 0.15);
}

.md-textstyle {
    font-family: "Josefin Sans", sans-serif !important;
    color: #414A5B;
    font-weight: 600;
    font-size: 18px;
    margin-top: 5%;
}

.md-textstyle1 {
    font-family: "Josefin Sans", sans-serif !important;
    color: #414A5B;
    font-weight: 600;
    font-size: 18px;
    margin-top: 5%;
}

.md-text {
    font-family: "Josefin Sans", sans-serif !important;
}

.md-textstyles {
    font-family: "Josefin Sans", sans-serif !important;
    color: #8B8B8B;
    font-weight: 400;
    margin-top: 5%;
    margin-left: 1em;
}

.md-textstyles1 {
    font-family: "Josefin Sans", sans-serif !important;
    color: #8B8B8B;
    font-weight: 400;
    margin-top: 5%;
    margin-left: 1.7em;
}

@media only screen and (max-width: 1600px) {
    .md-dcard{
        width: 87em;
    }
    .md-textstyle{
        font-size: 16px;
    }
    .md-textstyle1{
        font-size: 16px;
    }
}

@media only screen and (max-width: 1536px) {
    .md-dcard{
        width:83em;
    }
}

@media only screen and (max-width: 1440px) {
    .md-dcard{
        width:77em;
    }
    .md-textstyle{
        font-size: 14px;
    }
    .md-textstyle1{
        font-size: 14px;
    }
}

@media only screen and (max-width: 1366px) {
    .md-dcard{
        width:72em;
    }
    .md-textstyle{
        font-size: 12px;
    }
    .md-textstyle1{
        font-size: 12px;
    }
}

@media only screen and (max-width: 1280px) {
    .md-dcard{
        width:67em;
        height:7em;
    }
}

@media only screen and (max-width: 1024px) {
    .md-dcard{
        width:52em;
        height:7em;
    }
    .md-textstyle{
        font-size: 9px;
    }
    .md-textstyle1{
        font-size: 9px;
    }
}