.notifyTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  text-transform: uppercase;
  color: #414A5B;
  border: 2px solid #dedede;
  background-color: #ffffff;
  padding: 10px;
  margin: 10px 0;

}

.diagonal {
  height: 40px;
  width: 40px !important;
  border-radius: 35px;
  border: 1px solid !important;
  cursor: pointer;
  margin-right: 10px;
}

.container {
  display: flex;
  border: 2px solid #dedede;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  gap: 1em;
}

.darker {
  border-color: #ccc;
  background-color: #ddd;
}

.container::after {
  content: "";
  clear: both;
  display: table;
}

.activeIcon {
  color: green;
  justify-content: center;
  align-items: center;
  display: flex;
}

.inactiveIcon {
  color: gray;
  justify-content: center;
  align-items: center;
  display: flex;
}

.container img.right {
  float: right;
  margin-left: 20px;
  margin-right: 0;
}

.notificationHeader {
  color: #414A5B;
  font-size: 20px;
  font-weight: bold;

}

.notificationDate {
  font-family: "Josefin Sans", sans-serif !important;
  font-weight: 500;
  color: #848b97;

}

.notificationContentHigh {
  font-family: "Josefin Sans", sans-serif !important;
  font-weight: 300;
  color: red;
  /* margin-top: 0.5rem; */
}

.notificationContentLow {
  font-family: "Josefin Sans", sans-serif !important;
  font-weight: 300;
  color: orange;
  margin-top: 0.5rem;
}

.notificationView {
  float: right;
  margin-top: 3rem;
}

.notificationDelete {
  margin-top: 2rem;
}

.endcalldiv:hover {
  cursor: pointer;
}

.notification-images {
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
}

.notify-section {
  width: 4em;
  height: 4em;
}

.notification-popup {
  display: flex;
  gap: 0.2rem;
  justify-content: space-between;
  width: 100%
}

@media(max-width:768px) {
  .notificationHeader {
    font-size: 18px;
  }

  .notify-section {
    width: 3em;
    height: 2em;
  }

  .notificationContent {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 400px;
  }

}

@media(max-width:767px) {
  .container {
    max-width: initial;
  }
}

@media(max-width:575px) {
  .notification-popup {
    display: block;
  }

  .inactiveIcon {
    display: none;
  }

  .notificationContent {
    width: 100%;
  }

  .mobileview-notify {
    text-align: center;
    margin-top: 1rem;
  }
}