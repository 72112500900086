.ms-edit {
    margin-right: 1em;
    color: #009688
}

.ms-edit:hover {
    cursor: pointer;
}


.ms-delete {
    margin-right: 1em;
    color: #e91313
}

.ms-delete:hover {
    cursor: pointer;
}


/* .p-datatable .p-datatable-tbody > tr > td{
    text-align: center;
} */

.modal-close{
    background-color: gray !important;
    border-color:gray !important;
    border-radius: 5px;
    color:white;
}

@media(max-width:1199px){
    .ms-edit{
        margin-right: 0em;
    }
    .ms-delete {
        margin-right: 0em;
    }
}