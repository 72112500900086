.report-generate h3 {
    text-align: left;
    margin: 2rem 0rem;
    font-weight: 700;
    text-transform: uppercase;
    color: #414A5B;
    font-size: 20px;
    padding-top: 0px !important;
    white-space: nowrap;
}

.report-leftside-inner p {
    font-weight: 600;
    font-size: 18px;
    margin: 0.5rem 0rem;
}
.report-select {
    width: 100%;
    margin-bottom: 1rem;
}
.reports-inner {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 100% !important;
    margin: 0px !important;
}

.report-leftside-inner {
    padding: 1rem;
    background-color: #fff;
    border-radius: 10px;
}

.report-profil img {
    height: 160px;
    width: 160px;
    object-fit: cover;
}

.report-profile img {
    height: 100%;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.reports-rightsideinner {
    align-items: center;
    display: flex;
    justify-content: end;
}

.userdetails {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column !important;
}

.bloodpressure-text div {
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    color: #414A5B;
    margin: 1rem 0rem;
}

.download-btn div {
    text-align: right;
    margin: 2rem 0.5rem;
}

.glucose-graph,
.Weight {
    background-color: #fff;
    padding: 2rem;
    border-radius: 10px;
}

.profile-update {
    padding: 0px !important;
}

.textstylecolor1 {
    color: #009688;
    font-weight: bold;
    font-size: 20px;
}

.evenspace,
.measurment {
    margin-bottom: 10px;
}

.leftside-text {
    font-family: 'Josefin Sans' !important;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #414A5B;
}

.date-field {
    font-family: 'Josefin Sans' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: #8B8B8B;
}

.enable-section .Mui-checked {
    color: #009688 !important;
}

.time-measure {
    height: 25px;
}

.pdfdownload {
    display: flex;
    justify-content: end;
    align-items: center;
}

.middle-section {
    margin-bottom: 10px;
}

.p-button.p-button-warning,
.p-buttonset.p-button-warning>.p-button,
.p-splitbutton.p-button-warning>.p-button,
.p-fileupload-choose.p-button-warning {
    color: #ffffff;
    background: #009688;
    border: 1px solid #009688;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding: 0.75rem 1.25rem;
    margin: 0px;
}
@media(max-width:1440px) {
    .report-generate h3,
    .bloodpressure-text div {
        font-size: 18px;
    }
}

@media(max-width:1199px) {

    .reports-rightsideinner {
        text-align: center;
        display: block;
    }
}

@media(max-width:768px) {

    .report-leftside-inner {
        margin-bottom: 1rem;
    }

    .reports-rightside .MuiGrid-root {
        width: 100% !important;
        margin-left: 0px !important;
    }
}

@media(max-width:599px) {
    .userdetails {
        text-align: center;
    }
}