@import "~primeicons/primeicons.css";
/* @import "~primeng/resources/themes/nova-light/theme.css";
@import "~primeng/resources/primeng.min.css";
@import "~font-awesome/css/font-awesome.css"; */

body {
  background-color: #e9edf9 !important;
}
.scrollable-container::-webkit-scrollbar {
  width: 5.5em !important;

}

.seach-btn .p-inputtext:enabled:focus {
  box-shadow: none;
  
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: lightseagreen;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: lightseagreen;
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 981px) {
  #sticky-layout {
    margin-left: 160px !important;
  }
}

.idel-popup h1 {
  font-size: 17px;
}

.idel-popup p {
  font-size: 16px;
}

.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
  box-shadow: none;
}

.field-checkbox.ccm-text {
  margin-bottom: 0rem;
  margin-right: 1rem;
}

.field-checkbox.ccm-text .p-checkbox {
  display: flex;
  align-items: center;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: none !important;
}

.profile-save .field-container {
  margin: 1em 0em;
}

.p-link:focus {
  box-shadow: none;
}

.notification-popup {
  width: 12.5em !important;
  height: 6.5em;
  overflow-y: auto;
  overflow-x: clip;
}

.notification-popup .p-menuitem {
  /* padding: 0.5rem 0rem; */
}

.profile-popup .p-menuitem-text {
  white-space: nowrap;
}

/* scrollbar style start */

::-webkit-scrollbar {
  /* width: 5px; */
  width: 0.7em;
  height: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px grey;
  background-color: #009688;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb {
  /* background:grey; */

  background:#343a40;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
  background: gray;
}

/* scrollbar style end */

/* p-button style start */

.p-button , .p-button:hover{
  background: #009688 !important;
  border-color: #009688 !important;
  color: #fff !important;
  justify-content: center;
}
.p-button:focus{
  box-shadow: none;
}
.p-selectbutton .p-button {
  background: #ffffff !important;
  border: 1px solid #fff !important;
  color: #495057 !important;
  height: 52px;
}
.p-selectbutton .p-button.p-highlight {
  background: #009688 !important;
  border-color: #009688 !important;
  color: #ffffff !important;
}
.p-selectbutton .p-button.p-highlight:hover {
  background: #009688 !important;
  border-color: #009688 !important;
  color: #ffffff !important;
}
.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  box-shadow: none !important;
}
.p-selectbutton.p-buttonset.p-component {
  display: flex;
}
/* p-button style end */
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover , .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus{
  border-color: #ced4da !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover , .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight.p-focus{
  border-color: #005CC8 !important;
}

/* Start: Voice call popup */
.window-popup {
  z-index: 999999 !important;
  width: 300px !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
}
.window-popup.window-in {
  top: auto !important;
  left: auto !important;
  bottom: 8rem !important;
  transform: inherit;
  right: 2rem !important;
}
.window-popup .MuiBackdrop-root {
  opacity: 0 !important;
  display: none !important;
}
.window-popup .notifycard {
  border-radius: 0px;
  background-color: #000;
  padding: 1rem;
  position: absolute;
  width: 300px;
  border-radius: 8px;
}
.window-popup .header-modal {
  text-align: right;
  color: #fff;
  position: relative;
  top: 0rem;
  margin-bottom: 2rem;
}
.window-popup .notifycalldiv {
  color: #fff;
  margin-top: 2rem;
}
.mic-icon {
  background: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
}
.mic-icon svg{
  color: #000;
}
.call-icon {
  background: red;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.call-icon .Mui-disabled, .mic-icon .Mui-disabled {
  opacity: 0.5;
}
.call-icon svg {
  fill: #fff;
}
.window-popup .notifyphonetext, .window-popup .notifyphonenumb {
  font-size: 16px;
}
.window-in .notifyflex {
  display: none;
}
.window-in .header-modal {
  margin-bottom: 0rem;
}
.minimize-sec svg {
  position: relative;
  top: -8px;
}
.minimize-sec, .maximize-sec {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.minimize-sec svg, .maximize-sec svg{
  cursor: pointer;
}
.window-in .minimize-sec {
  display: none;
}
.window-popup .maximize-sec {
  display: none;
}




.Toastify__toast-body{
  padding:0px !important;
}



.window-in .maximize-sec {
  display: flex;
}
:focus-visible {
  outline: 0px !important;
}
@media(max-width:575px) {
  .window-popup.window-in {
    right: 1rem !important;
  }
}
.voicecall-popup-open {
  overflow: scroll !important;
 }
/* End: Voice call popup */
.notification-popup.msg-notification {
  width: 100% !important;
  height: 100% !important;
  justify-content: flex-start;
}
.notification-popup.msg-notification div {
  padding: 0rem 4rem 0rem 1rem;
}
.smobile-view .MuiBadge-root {
  position: absolute;
  top: 4px;
  right: 15px;
}
@media (min-width:769px){
  .mobileview-notify {
    width: 781px;
  }
}
@media (max-width:1199px){
  .notification-popup.msg-notification div {
    padding: 0rem 1rem 0rem 1rem;
  }
}
@media (max-width:500px){
  .header-part2{
    display: block !important;
  }
  .header-part {
    height: auto;
  }
  .sidebar-section1{
    padding-top: 11rem;
  }
  .smobile-view {
    justify-content: end;
  }
  .rbc-toolbar{
    display: flex !important;
    flex-direction: column;
  }
  .rbc-toolbar button {
    padding: 0.375rem;
  }
}
@media (max-width:400px){
  .dashbsearch-patient{
    flex-direction: column-reverse;
  }
  .seach-btn {
    margin-right: 0rem;
  }
  .seach-btn input {
    width: 100%;
  }
}
/* login style start */
.main-Conatiner {
  width: 100%;
  height: 100vh;
  background-image: url('../src/asset/Image/bg.png');
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-setion{
  background: linear-gradient(to left, #009688 200%, white 200%);
  padding: 1rem;
}
.reset-text{
  color: #000;;
  font-weight: 600;
  text-align: center;
}
.name-list {
  color: #009688;
  padding-bottom: 2px;
  font-family: 'Josefin Sans', sans-serif !important;
  font-size: 15px;
}
.footer .ftext {
  margin-top: 0px;
}
@media (max-height:760px) {
  .main-Conatiner.Register {
    display: block;
    height: 100%;
    padding: 3rem 0rem;
  }
}
@media (max-height:600px) {
  .main-Conatiner {
    display: block;
    height: 100%;
    padding: 3rem 0rem;
  }
}
@media(max-width:320px) {
  .name-list {
    font-size: 13px !important;
  }
}
/* login style end */