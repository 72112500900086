.sidemenu {
  height: 100%;
}

.sidebar-list {
  height: 100%;
  position: fixed;
  overflow-y: auto;
}
.sidebar-list::-webkit-scrollbar {
  width: 0px;
}
.footerstyle {
  color: white;
  margin-top: 5em;
  padding: 4px;
  justify-content: center;
  display: flex;
  font-size: 17px;
}

.sidemenu-listed {
  position: relative;
  z-index: 99999;
}

@media(max-width:980px) {
  .mobile-menu {
    z-index: 999;
    position: fixed;
  }
}

@media(max-width:767px) {
  .org-head {
    text-align: center;
    margin-left: 0px;
    padding: 0.5rem 0px;
    font-size: 13px;
  }

  .imgcolor {
    width: 30px !important;
  }
}

.sideleft {
  width: "180px";
  background: "lightgrey";
  height: "100vh"
}

.sideflex {
  background-image: "`url(${sidebar_bg})`";
  background-position: "center";
  background-repeat: " no-repeat";
  background-size: " cover";
  height: "100vh";
}

.imgcolor {
  height: auto;
}

.fprivacy {
  color: white;
  margin-top: -0.5em;
  padding: 4px;
  justify-content: center;
  display: flex;
  font-size: 17px;
}
