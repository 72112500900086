body {
  margin: 0;
  font-family: 'Josefin Sans', sans-serif !important;
  background-color: #eceff1;
  color: #009688;
}

.app {
  margin: 0 auto;
  width: 300px;
  height: 200px;
  position: relative;
  border-radius: 10px;
}

h3 {
  color: white;
  text-align: center;
  padding-top: 8px;
  letter-spacing: 1.2px;
  font-weight: 500;
}

p {
  font-size: 28px;
}

.stopwatch-card {
  position: absolute;
  text-align: center;
  top: 60px;
  left: 50%;
  transform: translate(-50%, -50%);
}

button {
  outline: none;
  background: transparent;
  border: 1px solid #009688;
  padding: 5px 10px;
  border-radius: 7px;
  color: #009688;
  cursor: pointer;
}

.video-overlay {
  border: 1px solid #EAEDF5;
  color: #544F56;
  border-radius: 10px;
  padding: 15px;
  text-align: center;
}

.timer-button button {
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}

.modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-container {
  display: flex;
  /* align-items: center;
  justify-content: center; */
}

.pause-timer {
  border: 1px solid #EAEDF5;
  border-radius: 10px;
  background-color: #009688;
}

.addtimer {

  height: 1rem
}

.timer-logo {
  margin: -11px
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}