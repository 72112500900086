.p-menu-list span.p-menuitem-text {
  margin-left: 12px;
}

.p-menu .p-submenu-header {
  margin: 0;
  color: #343a40;
  background: #ffffff;
  font-weight: 700;
  padding: 0px !important;
}

* {
  font-family: 'Josefin Sans', sans-serif !important;
}
.modal-content {
  margin-top:4em;
}
.header-part {
  background: #ffffff;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  position: fixed;
  z-index: 99;
}

.header-part2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 160px);
}

.diagonal1 {
  height: 40px;
  width: 40px !important;
  border-radius: 35px;
  border: 1px solid !important;
  cursor: pointer;
  margin: -4px 0px 0px 0px;
}

.addpatient1 {
  padding: 20px;
  background-Color: #e9edf9;
  height: 100%;
}

.sidebar-section1 {
  padding: 20px;
  background-color: #e9edf9;
  padding-top: 5rem;
  padding-bottom: 8rem;

}

.smobile-view {
  padding: 10px;
  display: flex;
  align-items: center;
}

.care-provider {
  display: flex;
  align-items: center;
}

.leftside-logo {
  display: flex !important;
}

.care-provider img {
  width: 40px;
  height: 40px;
}

.carepro-text-doc {
  font-size: 14px;
  color: #0a0a0a;
}

.drtextsize {
  font-size: large;
}

.carepro-text {
  padding-left: 12px;
  font-size: 18px;
  color: #009688;
}

.card-hghlght1 {
  pointer-events: auto;
  transform: scale(1);
  transition: all 0.4s;
}

.card-hghlght1:hover {
  opacity: 5;
  transform: scale(1.02);
  box-shadow: 0 5px 10px 0 #143D55;
}

.notification-popup1{
  width: 9em !important;
  height: 2.5em !important;
  overflow-y: auto;
  padding: 8px;
}

@media (max-width:1440px){
  .dash-card-section {
    font-size: 16px;
  }

  .dash-card-img img {
    width: 50px;
  }

  .dash-card-img {
    margin-right: 1rem;
  }
}
@media(max-width:980px) {
  .leftside-logo {
    padding-left: 3rem;
  }
  .header-part2 {
    width: 100%;
  }
}

@media(max-width:768px) {
  .header-part {
    margin-left: 0px;
  }

  .leftside-logo {
    padding-left: 3rem;
  }

  .preserveAspectRatio {
    z-index: 999;
    position: fixed;
    opacity: 8;
  }
}

@media(min-width:1200px) and (max-width:1440px) {

  .number {
    font-size: 2em !important;
    margin: 0.2em !important;
  }

  .dcard {
    padding: 0.5rem !important;
  }
}

@media(max-width:545px) {
  .carepro-text {
    font-size: 15px;
  }
}

@media(max-width:425px) {
  .css-k4qjio-MuiFormHelperText-root.Mui-error {
    font-size: 10px;
    margin: 0px 2px;
  }

  .leftside-logo img {
    width: 25px !important;
  }

  .p-menu {
    width: 30%;
  }

  .p-menu .p-submenu-header {
    padding: 0px;
  }
}

@media(max-width:320px) {
  .smobile-view {
    right: -2rem !important;
  }

  .p-menu .p-menuitem-link {
    padding: 0.5rem 0.25rem;
    font-size: 12px;
    text-align: center;
    display: block;
  }
}