.ecard {
  background: rgb(255, 255, 255);
  padding: 20px;
  border-radius: 0px 0px 10px 10px;
  width: 100em;
}

.epinput-container {
  width: 24em;
}

.ephelloo {
  font-family: 'Josefin Sans', sans-serif !important;
  font-weight: 500;
  margin-top: 1%;
  margin-left: 2%;
  font-size: 20px;
  display: flex;
  justify-content: center;
}

.helloo {
  font-family: 'Josefin Sans', sans-serif !important;
  font-weight: 500;
  margin-top: 1%;
  margin-left: 2%;
  font-size: 15px;
  display: flex;
  justify-content: center;
}

.elabel {
  color: #009688;
  padding-bottom: 2px;
  font-family: 'Josefin Sans', sans-serif !important;
  margin-left: 1.5em;
  font-size: 16px;
}

.einputfield {
  width: 97%;
  margin-left: 1em;
  margin-bottom: 1em;
  margin-top: 0.5em;
}

.editp-btn {
  margin-left: 2em;
  margin-top: 10em;
}

.elong {
  display: flex;
}

.editp-button {
  width: 10%;
  height: 3em;
  color: white;
  background-color: #009688;
  border-radius: 3px;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Josefin Sans', sans-serif !important;
  justify-content: center;
  border-color: #009688;
}

.editcard {
  height: 6vh;
  background-color: #009688;
  border-width: 1em;
}

.edittext {
  color: #eff2fc;
  padding: 1em;
  font-size: 18px;
  font-weight: 900;
}

.epspan {
  font-size: 11px;
  color: #909090;
  font-weight: 400
}

a:-webkit-any-link {
  text-decoration: none;
}

@media only screen and (max-width: 1600px) {
  .ecard {
    width: 84em;
  }

  .epinput-container {
    width: 19.5em;
  }
}

@media only screen and (max-width: 1536px) {
  .ecard {
    width: 80em;
  }

  .epinput-container {
    width: 18.5em;
  }
}

@media only screen and (max-width: 1440px) {
  .ecard {
    width: 73em;
  }

  .epinput-container {
    width: 17em;
  }
}

@media only screen and (max-width: 1366px) {
  .ecard {
    width: 69em;
  }

  .epinput-container {
    width: 16em;
  }

  .ephelloo {
    font-size: 17px;
  }

  .editp-button {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1280px) {
  .ecard {
    width: 64em;
  }

  .epinput-container {
    width: 14.7em;
  }

  .ephelloo {
    font-size: 17px;
  }

  .editp-button {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1024px) {
  .ecard {
    width: 48em;
  }

  .ephelloo {
    font-size: 13px;
  }
}