.chatcommon {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 85vh
}

.chatgroup {
    width: 25%;
    background-color: #ffffff;
    border-radius: 8px;
}

.chatindividual {
    width: 75%;
    background-color: rgb(243, 247, 247);
    border-radius: 8px;
}

.chatcommonflex {
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin-top: 3%;
}

.menuchat {
    width: 25em;

}

.chatmsg {
    color: black;
    font-size: 20px;
    font-weight: 600;
}

.menucsearchchat {
    padding: 15px;
}

.indichat {
    width: 100%;
    background-color: #ffffff;
    height: 75px;
    padding: 13px;
}

.chattextstyle {
    color: aliceblue;
}

.chatpersonname {
    padding: 1.5em;
    position: absolute;
}

.typing {
    padding: 15px;
    margin-left: 3em;
}

.chatting {
    padding: 15px;
    margin-top: 2em;
}

.chatpersonname1 {
    padding: 0.5em;
    font-weight: 500;
    position: absolute;
    color: black;
}

.chatpersonname2 {
    padding: 0.5em;
    position: absolute;
    top: 6.9em;
}

.chatmsg1 {
    padding: 0.8em;
    margin-left: 12px;
    height: 2.5em;
    width: 12em;
    position: absolute;
    border-radius: 15px;
    background-color: #F1F1F1;
}

.chatmsg2 {
    padding: 0.8em;
    margin-left: 12px;
    height: 2.5em;
    width: 7em;
    position: absolute;
    border-radius: 15px;
    margin-top: 3em;
    background-color: #F1F1F1;
}

.chatmsg2i {
    padding: 0.8em;
    margin-left: 13px;
    height: 2.5em;
    width: 13em;
    position: absolute;
    border-radius: 15px;
    margin-top: 3em;
    background-color: #F1F1F1;
}

.chatmsg3 {
    padding: 0.8em;
    margin-left: 12px;
    height: 2.5em;
    width: 12em;
    position: absolute;
    border-radius: 15px;
    margin-top: 6em;
    background-color: #F1F1F1;
}

.chatmsg4 {
    padding: 0.8em;
    margin-right: 47px;
    height: 2.5em;
    width: 9em;
    position: absolute;
    border-radius: 15px;
    margin-top: 0em;
    background-color: #009688;
}

.chatmsg5 {
    padding: 0.8em;
    margin-right: 47px;
    height: 2.5em;
    width: 9em;
    position: absolute;
    border-radius: 15px;
    margin-top: 3em;
    background-color: #009688;
}

.chatmsg6 {
    padding: 0.8em;
    margin-right: 47px;
    height: 2.5em;
    width: 14em;
    position: absolute;
    border-radius: 15px;
    margin-top: 6em;
    background-color: #009688;
}


.onlinetxt {
    font-size: 12px;
    margin-left: 0.5em;
    position: absolute;
    color: black;
}

.chattime {
    display: flex;
    flex-direction: row-reverse;
    margin-top: -1.8em;
    margin-right: 2em;
    margin-bottom: 0.5em;
    color: darkgray;
}

.chatborder:hover {
    background-color: #EEF2F2;
    cursor: pointer;
    border-radius: 5px;
}

.chatborder {
    padding: 5px;
}

.chatting1 {
    display: flex;
    flex-direction: row-reverse;
    padding: 15px;
    margin-top: 5em;
}

.paperclip {
    margin-left: 2em;
    margin-bottom: -2em;
    cursor: pointer;
}

.searchbox {
    width: 24em;
    margin-top: 0em;
    background-color: #F3F3F3;
}

.smssend-icon {
    text-align: center !important;
    align-items: center !important;
    display: flex !important;
}

button.MuiButton-containedPrimary:hover {
    background-color: #009688 !important;
}

.chat-section {
    width: 100%;
}

.borderRight500 {
    border-right: 1px solid #e0e0e0;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.bordercenter500 {
    width: 200px !important;
    height: 200px !important;
    margin: auto !important;
}

.bordercenter400 {
    margin-top: 3rem;
    width: 100%;
    text-align: center;
    color: #009688;
    font-size: 20px;
    font-weight: bold;
}

.profile-below {
    text-align: center;
    font-size: 18px;
    padding-top: 0.5rem
}

.chat_Content {
    padding-top: 0.5rem;
}
.chat-section {
    width: 100%;
    margin: 0px;
}
@media(max-width:991px) {
    .chating-field {
        background-color: #ffffff;
    }
}


@media(max-width:768px) {
    .chating-field {
        background-color: #ffffff;
    }
}

@media(max-width:575px) {
    .smssend-icon button {
        max-width: 30px;
        min-width: 30px;
    }

    .bordercenter500 {
        width: 150px !important;
        height: 150px !important;
    }

    .smssend-icon {
        display: block;
    }
}



.messageArea{
height: 55vh;
overflow-y: auto;
 }

.patientChat {
         max-width: 25rem;
         background-color: #e1ffc7;
         float: right;
         padding: 3px 17px;
         border-radius: 16px 16px 0 16px;
         border: 1px solid #e1ffc7;
}
.doctorChats{
            float: "left";
             padding: "3px 17px";
             background-color: "#6c8ea496";
             border-radius: "20px 20px 20px 0";
             border: "1px solid #6c8ea496";
             max-width: "25rem";
}


.btn {
    margin: "0.5em";
    background: rgb(0, 150, 136);
}

