.notifycard {
  border-radius: 8px;
  flex-wrap: wrap;
  background-color:black;
  padding: 1rem;
}

.entirediv {
  display: flex;
  justify-content: center;
 align-items: center;
 height: 100vh;
}

.notifyphonediv {
  text-align: center;
}

.notifycalldiv {
  justify-content: space-evenly;
  display: flex; 
  /* padding: 3px; */
  margin-top: 5%;
  align-content: center;
}
.notifyphoneflex {
  flex-direction: column;
  margin-top: 5%;
}
.notifyphonetext {
  font-family: 'Josefin Sans', sans-serif !important;
  font-style: normal;
  font-size: 25px;
  color: #fff;
}
.notifyphonenumb {
  font-family: 'Josefin Sans', sans-serif !important;
  font-style: normal;
  font-size: 20px;
  color: #fff;
  margin-top: 1%;
}
.endcalldiv {
  width: 50px;
  height: 50px;
}
