 .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
     border: 1px solid #e9ecef;
     background: #ffffff;
     min-width: 3rem;
     height: 3rem;
     line-height: 2rem;
     font-size: 1.5rem;
     z-index: 1;
     border-radius: 50%;
 }

 .p-steps .p-steps-item.p-highlight .p-steps-number {
     color: #009688;
 }

 .patient-container {
     width: 100%;
     height: 100%;
     background-image: url('../../asset//Image/bg.png');
     padding: 2rem;
 }

 .p-steps .p-steps-item.p-highlight .p-steps-title {
     color: #009688;
     text-transform: uppercase;
 }

 .p-steps .p-steps-item:before {
     content: " ";
     border-top: 1px solid #009688;
     width: 100%;
     top: 50%;
     left: 0;
     display: block;
     position: absolute;
     margin-top: -1rem;
 }

 .patient-bg {
     height: 100vh;
 }

 .field-one {
     background: #009688;
     padding: 20px;
     border-radius: 6px 6px 0px 0px;
     margin: -1.55em -1.3em 1em;
     color: white;
     font-weight: 500;
     font-size: 20px;
 }

 .info-popup {
     background: "#fff";
     border-radius: "6px";
     padding: "10px 25px";
     margin-top: "3em";
     margin-bottom: "1em";
     width: "90%";
     margin-left: "auto";
     margin-right: "auto"
 }

 .enable-field {
     padding-top: 1rem;
 }

 .informationsec {
     background-color: #fff;
     border-radius: 6px;
     width: 100%;
 }

 .information-text {
     background-color: #009688;
     padding: 20px;
     border-radius: 6px 6px 0px 0px;
     color: white;
     font-weight: 500;
     font-size: 20px;
 }

 .omron-para {
     color: #009688;

 }

 .tab-select,
 .information-tab {
     padding: 1.7rem;
 }

 .next-btn.p-button:hover {
     height: 65px !important;
 }

 .checkbox1 {
     align-items: center;
 }

 .rpm-field {
     margin-right: 1rem;
 }

 .checkbox1.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
     border-color: #e7e3e3;
 }

 @media(max-width:1199px) {
     .p-steps .p-steps-item.p-highlight .p-steps-title {
         font-size: 10px;
     }

     .patient-bg {
         height: 180vh;
     }
 }

 @media(max-width:768px) {
     .tab-select ul {
         display: block;
     }

     .inputfieldaside {
         margin: 0px !important;
     }
 }

 @media(max-width:767px) {
     .p-steps .p-steps-item.p-highlight .p-steps-title {
         font-size: 10px;
     }

     .patient-bg {
         height: 253vh;
     }

     .p-steps-item {
         margin-bottom: 15px;
     }

     .p-steps ul {
         display: block;
     }

     .p-steps .p-steps-item:before {
         border-top: none !important;
     }
 }