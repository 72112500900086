.ms-edit {
    margin-right: 1em;
    color: #009688;
    width: 45px !important;
}

.ms-edit:hover {
    cursor: pointer;
}


.ms-delete {
    margin-right: 1em;
    color: #e91313;
    width: 45px !important;
}

.ms-delete:hover {
    cursor: pointer;
}

.ms-reset {
    margin-right: 1em;
    color: #c2e73c;
    width: 28px !important;
}

.ms-reset:hover {
    cursor: pointer;
}


.tabular .p-datatable .p-datatable-tbody>tr>td {
    text-align: left;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
    /* padding: 1rem 1rem; */
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1em 1em;
    font-size: 18px;
    white-space: nowrap;
}

.dashtable1 {
    width: 100%;
    margin-top: 2em;
    font-family: 'Josefin Sans', sans-serif !important;
    color: #A1A1A1;
}

.addstafe-text {
    margin: 0px !important;

}

.section1 {
    align-items: center;
    padding: 0rem 0.5rem;
}

.img-icon2 {
    margin-right: 0em;
    top: 2.5em;
    position: relative;
    cursor: pointer;
}

.managestaff {
    color: #414A5B;
    font-size: 20px;
    font-weight: bold;
    margin: 1rem 0rem 0rem;
}

.add-icon {
    margin: 0px !important;
    cursor: pointer;
}

.managestaff-text {
    margin: 0px !important;
}

.staffname {
    position: absolute;
    padding-top: 10px;
    padding-left: 9px;
    left: 5rem;
}

.popupclose-btn {
    border-bottom: transparent !important;
}

@media(max-width:1440px) {}

@media(max-width:1199px) {
    .dashtable span {
        font-size: 15px;
    }

    .tabular .p-datatable .p-datatable-tbody>tr>td {
        font-size: 13px;
    }

    .tabular .p-datatable-wrapper {
        margin-right: -19rem;
        width: 100%;
        overflow-x: auto;
    }
}

@media(max-width:991px) {
    .icon-part {
        text-align: left;
    }

    .managestaff {
        font-size: 13px !important;
    }

    .name-field {
        font-size: 15px !important;
    }
}