.ml-title {
    font-size: 18px;
    font-weight: 800;
    font-family: 'Josefin Sans', sans-serif !important;
    color: #414A5B;
    padding: 20px;
}

.card {
    margin-top: 0em;
    width: 700em;
    font-size: 18px;
    direction: ltr;
}

.cardbody {
    margin-top: 0em;
    width:950%;
    font-size: 18px;
    /* position: fixed; */
    direction: ltr;
}

.cardbody1 {
    margin-top: 0em;
    width:945%;
    font-size: 18px;
    /* position: fixed; */
    direction: ltr;
}


.p-chart{
    direction: ltr;
}

.cardbloodsugarbio {
    margin-top: 0em;
    width: 300em;
    font-size: 18px;
    direction: ltr;
}

.cardtable {
    margin-top: 0em;
    /* width: 700em;
    font-size: 18px; */
    direction: rtl;
    cursor: pointer;
}


.tabledata {
    margin-top: 0em;
    cursor: pointer;
}

.cardscatter {
    margin-top: 0em;
    width: 400em;
    font-size: 18px;
}

.ml-text {
    font-family: 'Josefin Sans', sans-serif !important;
}

.p-buttonset .p-button:not(:first-of-type):not(:last-of-type) {
    padding: 1.1em;
}

.paname1 {
    position: relative;
    bottom: -0.1em;
    padding-left: 10px;
    font-size: 20px;
    color: #007BFF;
}

.MuiTypography-h5 {
    font-size: 1.5rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.334;
    letter-spacing: 0em;
    margin-top: 0.6em;
}
@media(max-width:1440px){
    .logs-section{
        height: 650px;
        overflow: hidden scroll;
    }
}