.paginator {
    margin-top: 1rem;
}
.p-paginator {
    background: transparent;
}
.p-paginator .p-paginator-pages .p-paginator-page{
    min-width: 2rem;
    height: 2rem;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #009688;
    border-color: #009688;
    color: #fff;
}
@media (min-width:768px){
    .p-paginator {
        justify-content: end;
    }
}