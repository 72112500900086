.main-Container {
    display: flex;
    flex-wrap: wrap;
    margin: 1em;
    margin-left: 1em;
    margin-top: 2em;
}

.image-container {
    display: flex;
    justify-content: space-between;
}

.img-icon3 {
    margin-right: 6em;
    margin-top: 1em;
}

.managepatient {
    color: #414A5B;
    font-size: 20px;
    font-weight: bold;
    margin: 1rem 0rem 0rem;
}

.add-patient {
    font-size: 11px;
    color: #575F6F;
}

.overflow-ellipsis1 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    position: relative;
    width: 150px;
  }


.tabular .p-datatable .p-datatable-tbody>tr>td {
    text-align: left;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1em 1em;
    font-size: 16px !important;
    white-space: nowrap;
}

.ms-del {
    margin-right: 1em;
    color: #FA5050
}

.p-datatable.p-datatable-selectable-cell .p-datatable-tbody>tr.p-selectable-row>td.p-selectable-cell:not(.p-highlight):hover {
    background: none;
    color: none;
}

.p-datatable.p-datatable-selectable-cell .p-datatable-tbody>tr.p-selectable-row>td.p-selectable-cell:focus {
    outline: none;
    outline-offset: none;
}

.paname {
    padding-left: 10px;
    font-size: 13px;
    text-overflow: ellipsis;
    overflow: hidden;
}
.icon-container {
    display: flex;
    justify-content: end;
}

.tabular .p-avatar img ,  .tabular .p-avatar{
    width: 45px;
    height: 45px;
}
.dashbord-table .p-avatar img {
    padding: 0.2rem;
}
.tabular .p-avatar-text {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 3px;
}

/* .p-datatable-scrollable .p-datatable-thead > tr, .p-datatable-scrollable .p-datatable-tbody > tr, .p-datatable-scrollable .p-datatable-tfoot > tr{
    width: 169%;
} */
.p-datatable-wrapper::-webkit-scrollbar {
    height: 2.5vh; /* Adjust thickness as needed */
  }
  
  .p-datatable-wrapper::-webkit-scrollbar-thumb {
    /* background-color: #888; */
    border-radius: 8px;
  }
  
  .p-datatable-wrapper::-webkit-scrollbar-track {
    /* background-color: #f1f1f1; */
  }
  
  .p-datatable-wrapper {
    scrollbar-width: auto;
    /* scrollbar-color: #888 #f1f1f1; */
  }
  
.overflow-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    position: relative;
    width: 250px;
  }


.ms-reset1 {
    margin-left: 1.2em;
    margin-right: -3em;
    color: #c2e73c;
    width: 28px !important;
}

.ms-reset1:hover {
    cursor: pointer;
}

.MuiButton-root{
    background-color: #009688 !important;
    color: white !important;
    margin-left: 1em !important;
}

.ms-edit1{
    margin-right: 0.5em;
    color: #009688;
    width: 23px !important;
}


@media(max-width:1440px) {
    .paname {
        text-overflow: ellipsis;
        width: 100px;
        overflow: hidden;
    }
}

@media(max-width:1199px) {
    .datatable-section {
        margin-right: -14rem;
        width: 100%;
        overflow-x: auto;
    }
}