.patient-container {
    width: 100%;
    height: 100%;
    background-image: url('../../asset//Image/bg.png');
}

.ap-header {
    color: white;
    text-transform: uppercase;
    font-size: 20px;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    font-weight: 500;
    margin-left: 1em;
    font-family: 'Josefin Sans', sans-serif !important;
}

.ap-format {
    color: #909090;
    font-weight: 500;
    font-size: 12px;
}

.addpatient-card {
    padding: 0px;
    margin: 0px;
    width: 80em;
    margin-left: auto;
    margin-right: auto;
    height: 35;
    margin-top: 0em;
}


.apinput-container {
    width: 24em;
}


.patient-label {
    font-size: 16px;
    font-weight: 400;
    color: #009688;
    display: flex;
    font-family: 'Josefin Sans', sans-serif !important;
    margin-bottom: 0.5em;
}

.patient-btn {
    display: flex;
    justify-content: end;
    margin-top: 5em;
}

.pbtn-style {
    width: 10em;
    justify-content: center;
}

.pbtn1-style {
    width: 10em;
    justify-content: center;
    margin-left: 1em;
    background-color: #14CC5D;
    border-color: #14CC5D;
}

.pcheck {
    display: flex;
    margin-top: 5%;
    font-size: 15px;
    width: 24em;
}

.csize {
    margin-top: 0em;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding: 0em;
    margin: 3px;
}

.pdropdown {
    background-color: white;
    width: 24em;
    height: 2.5em;
    display: flex;
    border-radius: 4px;
}

.rcheck {
    margin-top: 0em;
    margin-right: 0.9em;
    font-size: 16px;
    color: #303956;
}

.p-checkbox .p-checkbox-box {
    width: 15px;
    height: 15px;
    border-radius: 0px;
    margin-top: 0em;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
    font-size: 8px;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon:hover {
    color: #ced4da;
}

/* .apinput-container:hover{
    border-color:red;
} */

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #005CC8;
    background: #005CC8;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: #005CC8;
    background: #005CC8;
}

.p-checkbox:onhover{
    border-color: none;
}
@media(max-width:1440px){
    .patient-container{
        height: 100% !important;
    }
}
@media(max-width:1024px){
    .patient-container{
        height: 100% !important;
    }
}
@media(max-width:768px){
    .patient-container{
        height: 100% !important;
    }
}
@media(max-width:767px){
    .patient-container{
        height: 100% !important;
    }
}
