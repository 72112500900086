.myappointment {
    color: #414A5B;
    font-size: 20px;
    font-weight: bold;
    margin: 2rem 0rem;
    text-transform: uppercase;
}

.appointmentcontainer{
    display: flex;
    border: 2px solid #dedede;
    background-color: #ffff;
    border-radius: 5px;
    padding: 10px;
    margin-left: -5px !important;
    margin-bottom: 2rem;
}

.appointmentblock1{
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
    background-color: white;
    border-radius: 8px;
    font-size: 16px;
    padding: 1rem 2rem;
    font-weight: 900;
    color: #009688;
    box-shadow: 0 5px 20px 0 rgb(0 0 0 / 29%)
}

.appointmentcontent{
   text-align: center;
}

.appointmenttime {
    font-family: "Josefin Sans", sans-serif !important;
    font-weight: 300;
    color: #040608b0;
}

.appointmentcontent1 {
    font-family: "Josefin Sans", sans-serif !important;
    font-weight: 500;
    color: #0c0c0c;
}

.appointmenttopic {
    color: #009688;
    font-size: 20px;
    font-weight: bold;
}
.join-btn{
    text-align: center;
    align-items: center;
    justify-content: center;
}
.appoint-time{
    display: flex;
    justify-content: center;
}