.datepicker {
    width: 100%;
    height: 3.5em;
    border-radius: 6px;
    border-color: lightgray;
    margin-top: 5px;
    border: 1px solid lightgrey;
    padding: 0px 10px
}

.rbc-month-header {
    height: 2em;
    padding: 6px;
}

.rbc-header {
    text-transform: uppercase;
    color: #4A4A4A;
    font-size: 16px;
    font-weight: 700;
}

.rbc-toolbar button {
    font-family: 'Josefin Sans', sans-serif !important;
    text-transform: uppercase;
    font-size: 15px;
}


.rbc-toolbar .rbc-toolbar-label {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    color: #009688;
}

.rbc-btn-group {
    margin-right: 0em;
}

.rbc-btn-group>button:first-child:not(:last-child) {
    cursor: pointer;
}

.rbc-btn-group>button:not(:first-child):not(:last-child) {
    cursor: pointer;
}

.rbc-btn-group>button:last-child:not(:first-child) {
    cursor: pointer;
}

.rbc-month-view {
    background: white;
}

.cal-size {
    height: 700px !important;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
    background: #009688 !important;
    color: white !important;
}

.rbc-toolbar button.rbc-active:hover {
    background-color: #009688;
    color: white;
}

.rbc-toolbar button:hover {
    background-color: #009688;
    color: white;
}

.rbc-time-view {
    background: white;
}

.rbc-agenda-view {
    background: white;
}

.view-table {
    margin-top: 1rem;
}

.checkbox-icon {
    align-items: center;
}

.delete-btn {
    margin-right: 10px;
}
.button-list {
    margin-top: 4rem;
    text-align: center;
}
.add-event-popup .field-checkbox {
    margin-top: 1rem;
}
.calendar-text {
    text-align: left;
}

.event-tracker {
    margin-bottom: 1rem;
}

.calendar-text h1 {
    color: #414A5B;
    font-size: 20px;
    font-weight: bold;
    margin: 2rem 1rem;
}

.count-increase {
    color: #009688;
    cursor: pointer;
    margin-right: 1rem;
}

@media(max-width:1440px) {
    .delete-btn {
        font-size: 13px;
    }

    .event-btn {
        font-size: 13px;
    }
}

@media(device-width:1600px) {
    .delete-btn {
        font-size: 13px;
    }

    .event-btn {
        font-size: 13px;
    }
}

@media (max-width:960px){
    .add-event-popup .p-datatable .p-datatable-tbody > tr > td, .add-event-popup .p-datatable .p-datatable-thead>tr>th {
        padding: 0.8rem 0rem !important;
    }
}
@media(max-width:768px) {
    .rbc-toolbar {
        flex-wrap: initial !important;
    }

    .count-increase {
        margin-right: 0.5rem;
    }
}

@media(max-width:767px) {
    .rbc-toolbar {
        display: block !important;
    }

    .rbc-toolbar span {
        margin-bottom: 1rem
    }
    .add-event-popup {
        width: 95% !important;
    }
}