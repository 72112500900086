.forgotten {
  color: gray;
  font-size: 18px;
  margin-top: 1rem;
}
.title {
  display: flex;
  margin-top: 10%;
  color: #009688;
  font-family: 'Josefin Sans', sans-serif !important;
  font-weight: 500;
  margin-left: 0.5em;
  margin-bottom: 0.7em;
}
.title1 {
  margin-right: 44%;
  margin-top: 5%;
  color: #009688;
  font-style: bold;
  font-weight: bold;
}


.button-reset {
  font-family: 'Josefin Sans', sans-serif !important;
  justify-content: center;
  border-color: #009688;
  border-radius: 3px;
  font-size: 1rem;
}

.input-container {
  margin: 1em;
  width: 100%;
  margin-top: 5px;
  padding-top: 5px;
  margin-bottom: 10px;
}

.forgot-head{
  color: white;
  font-size: 4em;
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}

.forgot-two{
  color: white;
  margin-top: -1.2em;
  font-size: 2em;
  display: flex;
  justify-content: center;
}

.copy {
  color: rgb(132, 126, 126);
   margin-top: 1rem;
   margin-left: auto;
   margin-right: auto;
   display: flex;
   justify-content: center;
   padding-bottom: 5%;
}
.copy svg {
  margin: 4px;
}