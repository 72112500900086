.schedulecard {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.meetingcard {
  background-color: #fff;
  width: 65%;
  border-radius: 5px;
  height: 80vh;
}

.upcomingmeeting {
  background-color: #fff;
  width: 30%;
  border-radius: 5px;
}

.blanktext {
  display: flex;
  justify-content: center;
  margin-top: 14em;
}
       
.meetingicons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top:18%;
}

.iconflex {
  display: flex;
  justify-content: space-around;
  width: 40%;
  transform: translate(0%, 100%);
}

.upcomingtext {
  font-family: 'Josefin Sans', sans-serif !important;
  font-weight: bold;
}

.imageovertext {
  position: absolute;
  left: 38em;
  top: 4.5em;
  color: white;
  font-weight: 400;
  font-size: 40px;
}

.imageovertext1 {
position: absolute;
left: 92em;
top: 14em;
color: white;
font-weight: 100;
font-size: 16px;
}

.schedule-topic{
  margin-top: 20%;
  margin-left:-2em;
  font-size:17px;
}

.share-fsize{
  font-size: 17px;
}

.sched-style{
  margin-left:0.7em;
font-size:17px;
}

.join-style{
  margin-left:2em;
  font-size:17px;
}

@media only screen and (max-width: 1600px) {
  .imageovertext1{
    left:76em;
  }
  .imageovertext{
    left: 32em;
  }
  .share-fsize{
    font-size: 12px;
  }
  .sched-style{
    font-size: 12px;
  }
  .join-style{
    font-size: 12px;
  }
  .schedule-topic{
    font-size: 12px;
    margin-left: -6em;
    margin-top: 24.5%;
  }
}

@media only screen and (max-width: 1536px) {
  .imageovertext1{
    left:73em;
  }
  .imageovertext{
    left: 31em;
  }
}

@media only screen and (max-width: 1440px) {
  .imageovertext1{
    left:67em;
  }
  .imageovertext{
    left: 28em;
  }
}